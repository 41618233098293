body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  cursor: pointer;
}

.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  max-width: 340px;
  margin: auto;
}

ul {
  list-style: none;
}

.counter {
  display: flex;
  text-align: left;
  margin-bottom: 12px;
  font-weight: bold;
}

.counter span:first-child {
  flex: 1;
}

li {
  display: inline-block;
  min-width: 280px;
  text-align: left;
  margin: 3px;
  color: red;
}

li.completed {
  color: green;
  text-decoration: line-through;
}

li button {
  float: right;
  margin: 3px 12px;
}

button {
  max-width: 80px;
  margin: auto;
}

.error {
  color: red;
  padding-bottom: 20px;
}